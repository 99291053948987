import React from 'react'
import { useEffect, useState } from 'react'
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import env from "react-dotenv";
import '../styles/purchase.css'
import Login from './Login';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ButtonWrapper = ({ currency, showSpinner, idToken, purchaseCompletedHandler }) => {
    // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    const style = { "layout": "vertical", "shape": "pill" };
    const amount = "5";

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: currency,
            },
        });
    }, [currency, showSpinner]);


    return (<>
        {(showSpinner && isPending) && <div className="spinner" />}
        <PayPalButtons
            style={style}
            disabled={false}
            forceReRender={[amount, currency, style]}
            fundingSource={undefined}
            createOrder={(data, actions) => {
                return actions.order
                    .create({
                        purchase_units: [
                            {
                                amount: {
                                    currency_code: currency,
                                    value: amount,
                                },
                            },
                        ],
                    })
                    .then((orderId) => {
                        // Your code here after create the order
                        return orderId;
                    });
            }}
            onApprove={function (data, actions) {
                return actions.order.capture().then(async function () {
                    let currentDate = new Date();
                    // Your code here after capture the order
                    // call firebase function to indicate the account has purchase the product
                    //console.log(data);
                    let url = env.ACCOUNT_POST_PURCHASE
                    url += `?idToken=${idToken}&orderId=${data.orderID}`;
                    const requestOptions = {
                        method: 'POST'
                    };
                    const respVerify = await fetch(url, requestOptions);
                    if (respVerify.status !== 200) {
                        // there was an error!
                        toast.error('Your purchase completed, but we were unable to update your account.  Please click on Contact Us and let us know about the issue.');
                        return;
                    }
                    else {
                        toast.success('Your purchase has been completed and your account has been updated.  Thank you!');
                        purchaseCompletedHandler(data.orderID, currentDate.toString());

                    }
                });
            }}
        />
    </>
    );
}

function Purchase() {

    // This values are the props in the UI
    const amount = "5";
    const currency = "USD";
    const paypalClientId = env.PAYPAL_CLIENT_ID;

    const [showLogin, setShowLogin] = useState(true);
    const [idToken, setIdToken] = useState('');
    const [providerName, setProviderName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [purchaseCompleted, setPurchaseCompleted] = useState(false);
    const [orderID, setOrderID] = useState('');
    const [orderPurchased, setOrderPurchased] = useState('');

    function handleAction(flag, idToken, providerName, emailAddress, orderID, purchaseOrderDate) {
        // flag indicates if already purchased or not
        setProviderName(providerName);
        setEmailAddress(emailAddress);
        if (!flag) {
            setShowLogin(false);
            setIdToken(idToken);
        }
        else {
            setOrderID(orderID);
            setOrderPurchased(purchaseOrderDate);
            setPurchaseCompleted(true);

        }
    }

    function purchaseCompletedHandler(orderID, orderPurchased) {
        setOrderID(orderID);
        setOrderPurchased(orderPurchased);
        setPurchaseCompleted(true);
    }

    return (
        <div className='purchase'>
            <div className='content'>
                <h1>Purchase</h1>
                <p>Purchasing Your Shopping List gives the following benefits:</p>
                <ul>
                    <li>Removes all advertisements</li>
                    <li>Enable item images</li>
                    <li>Directly supports development of new features</li>
                </ul>
                <p>The cost to purchase is ${amount}.  This is a one-time purchase and will be good for the lifetime of the software.</p>

            </div>
            {purchaseCompleted ?
                <>
                    <h1 className='is-size-2'>Thank you for your purchase!</h1>
                    <p>We hope you enjoy Your Shopping List.</p>
                    <p>If you have any questions or problems, please click on <a href='/contactus'>Contact Us</a> and send us a message.</p>
                    <br />
                    <div className='level'>
                        <div className='level-item has-text-centered'>
                            <div>
                                <p className='heading'>Order ID:</p>
                                <p className='title'>{orderID}</p>
                            </div>
                        </div>
                        <div className='level-item has-text-centered'>
                            <div>
                                <p className='heading'>Purchased:</p>
                                <p className='title'>{orderPurchased}</p>
                            </div>
                        </div>

                    </div>
                </>
                :
                showLogin ?
                    <>
                        <p>Please login with Your Shopping List account to begin:</p>
                        <br />
                        <Login handleAction={handleAction} />
                    </>
                    :
                    <>

                        <p>Login Provider: {providerName}  Email: {emailAddress}</p>
                        <p>Login Successful!  Now you can proceed with your purchase...</p>
                        <br />
                        <PayPalScriptProvider options={{ "client-id": paypalClientId, "components": "buttons", "currency": currency }}>
                            <ButtonWrapper
                                currency={currency}
                                showSpinner={false}
                                idToken={idToken}
                                purchaseCompletedHandler={purchaseCompletedHandler}
                            />
                        </PayPalScriptProvider>
                    </>
            }
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
}

export default Purchase