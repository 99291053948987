import React from 'react'
import '../styles/deletealldata.css'

function DeleteAllData() {
    return (
        <div className='deletealldata'>
            <h1 className='title'>Delete All Data</h1>
            <div className='content'>
                <p>We are currently developing an automated Delete All Data function.  In the meantime, please <a href="/contactus">contact us</a> and request for data deletion.</p>
            </div>
        </div>

    )
}

export default DeleteAllData