import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import env from "react-dotenv";
import Navbar from './Navbar';
import Hero from './Hero'
import Download from './Download';
import HowToUse from './HowToUse';
import Support from './Support';
import Footer from './Footer';
import Terms from './Terms';
import Privacy from './Privacy';
import ContactUs from './ContactUs';
import Webapp from './Webapp';
import DeleteAllData from './DeleteAllData';
import PageNotFound from './PageNotFound';
import About from './About';
import Purchase from './Purchase';

function App() {
  if (env.COMING_SOON === "true") {
    return (
      <BrowserRouter>
        <div className='App'>
          <Navbar />
          <Routes>
            <Route path="/" element={<Hero />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/about" element={<About />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          <Footer />
        </div>
      </BrowserRouter>

    );
  }
  else {
    return (

      <BrowserRouter>
        <div className="App">
          <Navbar />
          <Routes>
            <Route path="/" element={<Hero />} />
            <Route path="/howtouse" element={<HowToUse />} />
            <Route path="/download" element={<Download />} />
            <Route path="/support" element={<Support />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/webapp" element={<Webapp />} />
            <Route path="/deletealldata" element={<DeleteAllData />} />
            <Route path="/about" element={<About />} />
            <Route path="/purchase" element={<Purchase />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
