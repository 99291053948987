import React from 'react'
import env from "react-dotenv";
//import HeroMovie from '../assets/hero.mp4'
import HeroMovie2 from '../assets/hero2-1.mp4'
import HeroPoster from '../assets/hero2.png'

import '../styles/hero.css'

function Hero() {
    if (env.COMING_SOON === "true") {
        return (
            <div className='hero--hero'>
                <video className='hero--video' alt="hero mp4" src={HeroMovie2} loop autoPlay muted playsInline poster={HeroPoster} />
                <div className="hero--overlay"></div>
                <div>
                    <h1 className='is-size-1 has-text-centered has-text-white'>Welcome to Your Shopping List</h1>
                    <h3 className='is-size-3 has-text-centered has-text-white'>Manage your shopping lists with ease on Phone, Tablets, and PCs</h3>
                    <button className='button is-size-3' type="button">COMING SOON!</button>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className='hero--hero'>
                <video className='hero--video' alt="hero mp4" src={HeroMovie2} loop autoPlay muted playsInline poster={HeroPoster} />
                <div className="hero--overlay"></div>
                <div className="hero--text">
                    <h1 className='is-size-1 has-text-centered '>Welcome to Your Shopping List</h1>
                    <h3 className='is-size-3 has-text-centered has-text-white'>Manage your shopping lists with ease on Phone, Tablets, and PCs</h3>
                    <button className='hero--button' type="button">Let's Get Started</button>
                </div>
            </div>
        )
    }
}

export default Hero