import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCj54VVKVC9ODdPT7v-qvy4y-IoL3zh0Hc",
    authDomain: "your-shopping-list-444b9.firebaseapp.com",
    databaseURL: "https://your-shopping-list-444b9-default-rtdb.firebaseio.com",
    projectId: "your-shopping-list-444b9",
    storageBucket: "your-shopping-list-444b9.appspot.com",
    messagingSenderId: "966930519486",
    appId: "1:966930519486:web:60484f59911118e0b4d5ef",
    measurementId: "G-5VVL1TG79Z"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);