import React from 'react'
import { useState, useRef } from 'react';
import env from "react-dotenv";
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import { app } from '../firebase-config';
import { getAuth, signInWithEmailAndPassword, signOut, GoogleAuthProvider, signInWithPopup, FacebookAuthProvider } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EmailLoginButton from './EmailLoginButton';
import '../styles/login.css';

function Login({ handleAction }) {

    const emailRef = useRef(null);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showEmail, setShowEmail] = useState(false);
    const [formValid, setFormValid] = useState(true);

    async function checkPurchaseStatus(idToken, providerName, emailAddress) {
        const auth = getAuth();
        try {
            // now we need to check if we have already purchased
            let url = env.ACCOUNT_PURCHASE_STATUS;
            url += `?idToken=${idToken}`;
            const requestOptions = {
                method: 'GET'
            };
            const respVerify = await fetch(url, requestOptions);
            if (respVerify.status !== 200) {
                // there was an error!
                signOut(auth);
                toast.error('We are unable to verify your account status.  Please try again.');
                return;
            }
            else {
                const json = await respVerify.json();
                if (json.purchased === true) {
                    // callback function to whoever invoked us
                    // format the purchaseOrderDate to something more friendly
                    const pod = new Date(json.purchaseOrderDate);
                    handleAction(true, idToken, providerName, emailAddress, json.orderID, pod.toString());
                }
                else {
                    handleAction(false, idToken, providerName, emailAddress);
                }
            }
        }
        catch (error) {
            signOut(auth);
            toast.error('We are unable to verify your account status.  Please try again.');
        }

    }

    function onEmailClick() {

        setShowEmail(true);
        emailRef.current.focus();

    }

    async function onGoogleClick() {
        const provider = new GoogleAuthProvider();
        provider.addScope("https://www.googleapis.com/auth/userinfo.email");
        const auth = getAuth();
        signInWithPopup(auth, provider).then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            checkPurchaseStatus(user.accessToken, "Google", user.email);
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            if ((errorCode === 'auth/cancelled-popup-request') || (errorCode === 'auth/popup-closed-by-user')) {
                return;
            }
            else {
                console.error(errorCode, errorMessage);
                toast.error('There was an error attempting to login with Google.');
            }
        });
    }

    function onFacebookClick() {
        const provider = new FacebookAuthProvider();
        provider.addScope("email");
        const auth = getAuth();
        signInWithPopup(auth, provider).then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = FacebookAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            checkPurchaseStatus(user.accessToken, "Facebook", user.email);
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = FacebookAuthProvider.credentialFromError(error);
            if ((errorCode === 'auth/cancelled-popup-request') || (errorCode === 'auth/popup-closed-by-user')) {
                return;
            }
            else {
                console.error(errorCode, errorMessage);
                toast.error('There was an error attempting to login with Facebook.');
            }
        });

    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password).then(async (response) => {
            //console.log(response);
            const idToken = response._tokenResponse.idToken;
            checkPurchaseStatus(idToken, "Email", email);
        })
            .catch((error) => {
                toast.error('Please check your email address and password, and try again.');
            });


    }

    const emailChange = (event) => {
        const value = event.target.value;

        setEmail(value);
    }

    const passwordChange = (event) => {
        const value = event.target.value;

        setPassword(value);
    }

    const handleEmailCancel = (event) => {
        event.preventDefault();
        setShowEmail(false);
        setEmail('');
        setPassword('');
        setFormValid(true);
    }

    return (
        <div>
            <div className='loginWidth content'>
                <div className='control' style={{ display: showEmail ? 'initial' : 'none' }} >
                    <form onSubmit={handleSubmit}>
                        <div className="field">
                            <label className="label">Email Address</label>
                            <div className="control">
                                <input ref={emailRef} name='email' className="input" type="email" required value={email} onChange={emailChange} />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Password</label>
                            <div className="control">
                                <input name='pwd' className="input" type="password" required value={password} onChange={passwordChange} />
                            </div>
                        </div>
                        <div className="field is-grouped">
                            <div className="control">
                                <button className="button is-link" disabled={!formValid}>Submit</button>
                            </div>
                            <div className="control">
                                <button type='button' className="button is-link is-light" onClick={handleEmailCancel}>Cancel</button>
                            </div>
                        </div>
                    </form>
                    <hr />
                </div>
                <div >
                    <EmailLoginButton onClick={onEmailClick} style={{ display: showEmail ? 'none' : 'initial' }} />
                    <GoogleLoginButton onClick={onGoogleClick} />
                    <FacebookLoginButton onClick={onFacebookClick} />
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </div>
        </div>

    )
}

export default Login