import React from 'react'
import { Link } from 'react-router-dom';
import '../styles/footer.css'
import Logo from '../assets/shopping-list-v2-ios.png'
import { SocialIcon } from '../react-social-icons/build/react-social-icons';


function Footer() {

    const [isActive, setisActive] = React.useState(false);

    return (
        <nav className="navbar is-fixed-bottom">
            <div className="navbar-brand">
                <a className="navbar-item" href="/"><img src={Logo} alt="Logo"></img></a>

                <a onClick={() => {
                    setisActive(!isActive);
                }} role="button" className={`navbar-burger burger ${isActive ? "is-active" : ""}`} aria-label="menu" aria-expanded="false" data-target="footerMenu">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
            <div id="footerMenu" className={`navbar-menu ${isActive ? "is-active" : ""}`}>
                <div className="navbar-start">
                    <Link className="navbar-item" to="/terms">Terms</Link>
                    <Link className="navbar-item" to="/privacy">Privacy</Link>
                    <Link className="navbar-item" to="/contactus">Contact Us</Link>
                    <Link className="navbar-item" to="/about">About</Link>
                </div>
                <div className='navbar-end'>

                    <SocialIcon className="navbar-item mr-3" network="mastodon" url="https://mstdn.social/@yourshoppinglist" />
                    <SocialIcon className="navbar-item mr-3" url="https://www.facebook.com/profile.php?id=100089222401392" />
                    <SocialIcon className="navbar-item mr-3" url="https://twitter.com/yourshoppinglst" />
                </div>
            </div>
            {/* <small className="footer--copyright">Copyright 2022 Salter Diaz Solutions LLC</small> */}

        </nav>
    )
}

export default Footer