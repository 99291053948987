import React from 'react'
import '../styles/download.css'
import AppStoreBadge from '../assets/app-store-badge.png'
import GooglePlayBadge from '../assets/google-play-badge.png'
import ChromeWebInstall from '../assets/chrome-web-install.png'

function Download() {
    return (
        <section className="download">
            <h1 className="title">Download</h1>
            <div className="content">
                <p>Your Shopping List is avaiable as an app for Android and Apple devices, as well as on any browser.</p>
                <p>Please choose the following to download to you device:</p>
                <p className="subtitle">For Android devices, including Google, Samsung, and Motorola</p>
                <a className="" href='https://play.google.com/store/apps/details?id=com.salterdiazsolutions.yourshoppinglist'><img height="180" width="320" alt='Get it on Google Play' src={GooglePlayBadge} /></a>
                {/* <p className="notification is-info">Coming Soon!</p> */}
                <p className="subtitle">For Apple devices, including iPhone and iPad</p>
                <p className="notification is-info">Coming Soon!</p>
                <a className='is-hidden' href='itms-apps://something'><img alt='Download on App Store' src={AppStoreBadge}></img></a>
                <p className="subtitle">For Web Browsers</p>
                <p>You can access the Web app by clicking on the "Web App" button at the top right.</p>
                <p>You can also access the Web app by using the link <a target="_blank" rel="noreferrer" href="https://app.yourshoppinglist.net">app.yourshoppinglist.net</a> </p>
                <p>Finally, you can install the web app to your device by using the above link to open the web app in the browser, then selecting your browser's Install button.  On Chrome browsers, it looks like this:</p>
                <img src={ChromeWebInstall} alt="Screenshot of Chrome Web Install"></img>
            </div>
        </section>
    )
}

export default Download