import React from 'react'
import '../styles/howtouse.css'

function HowToUse() {
    return (
        <div className='howtouse'>
            <div className="header">
                <h1 className="title">How To Use</h1>
                <section className="section">
                    <h3 className="title">Registration</h3>
                    <div className="content">
                        <p>You will first need to create an account. On the app, click on the Create An Account button.</p>
                        <p>Then, you can register in three ways:</p>
                        <ol>
                            <li>By email: You can provide your email address and a password.  The password needs to be at least 6 characters in length.  After registering with email, a verification email will be sent. You will need to follow the instructions to verify your email account before you can access the app.</li>
                            <li>By Google:  If you have a Google account, select the Continue with Google option to automatically use your Google Account to sign in.</li>
                            <li>By Facebook:  If you have a Facebook account, select the Continue with Facebook option to automatically use your Facebook Account to sign in.</li>
                        </ol>
                        <p>Once you have registered, you will see your new Shopping List.</p>
                    </div>
                </section>
                <section className="section">
                    <h3 className="title">Items</h3>
                    <div className="content">
                        <p>To add an item, select the blue + button at the bottom right.</p>
                        <p>In the Add New Item window, enter the Item Name.  You can optionally add a description ("1 can","a dozen"), and you can select a
                            Category.  Once you have entered the information, select the Save button.</p>
                        <p>To mark an item as done, swipe right on the item to reveal the Check button, and then tap the button.  To uncheck an item, perform the same action.</p>
                        <p>To delete an item, swipe left on the item to reveal the Delete button, then tap.</p>
                        <p>To edit an item, long press on the item.  An Edit Item window will appear.  Make your changes and tap Save.</p>
                    </div>
                </section>
                <section className="section">
                    <h3 className="title">Lists</h3>
                    <div className="content">
                        <p>To create new lists, click the "hamburger" menu at the top left.  Then tap on the Create New List button, and enter the name of the new list.</p>
                        <p>To switch to a different list, tap the "hamburger" menu at the top left, and then tap the name of the list to display.</p>
                    </div>
                </section>
            </div>
        </div>

    )
}

export default HowToUse