import React from 'react'
import { NavLink } from "react-router-dom";
import { Link } from 'react-router-dom';
import env from "react-dotenv";
//import '../styles/navbar.css'

function Navbar() {
    const [isActive, setisActive] = React.useState(false);

    function openWebApp() {
        window.open('https://app.yourshoppinglist.net','_blank');
    }

    if (env.COMING_SOON === "true") {
        return (
            <nav className="navbar is-fixed-top has-shadow" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <a href="/"><h1 className='navbar-item is-size-4'>Your Shopping List</h1></a>
                </div>
            </nav>
        )
    }
    else {
        return (
            <nav className="navbar is-fixed-top has-shadow" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <h1 className='navbar-item is-size-4'>Your Shopping List</h1>
                    <a onClick={() => {
                        setisActive(!isActive);
                    }} role="button" className={`navbar-burger burger ${isActive ? "is-active" : ""}`} aria-label="menu" aria-expanded="false" data-target="navbarmenutarget">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>
                <div id="navbarmenutarget" className={`navbar-menu ${isActive ? "is-active" : ""}`}>
                    <div className="navbar-start">
                        <Link
                            className="navbar-item"
                            to="/"
                            exact="true"
                        >
                            Home
                        </Link>
                        <Link
                            className="navbar-item"
                            to="/howtouse"
                            
                        >
                            How To Use
                        </Link>
                        <NavLink
                            className="navbar-item"
                            to="/download"
                            exact="true"
                        >
                            Download
                        </NavLink>
                        <NavLink
                            className="navbar-item"
                            to="/support"
                            exact="true"
                        >
                            Support
                        </NavLink>
                        <NavLink
                            className="navbar-item"
                            to="/purchase"
                            exact="true"
                        >
                            Purchase
                        </NavLink>


                    </div>
                    <div className="navbar-end">
                        <div className='navbar-item'>
                            <div className="buttons">
                                {/* <a className="button is-primary" href="/login">Log In</a>
                                <a className="button" href="/signup">Sign Up</a> */}
                                <button className='button is-primary' onClick={openWebApp}>Web App</button>
                            </div>
                        </div>
                    </div>

                </div>
            </nav>
        )
    }


}

export default Navbar