import React from 'react'
import '../styles/webapp.css'

function Webapp() {

    React.useEffect(() => {
        window.open('https://app.yourshoppinglist.net', '_blank');
    }, [])

    return (
        <></>
        /* <div className='webapp'>
            <iframe className='webapp--iframe' title="Your Shopping List Web App" src="https://app.yourshoppinglist.net" ></iframe>
        </div> */
        
    )
}

export default Webapp