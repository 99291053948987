import React from 'react'
import '../styles/support.css'

function Support() {
    return (
        <div className='support'>
            <h1 className="title">Support</h1>
            <h3 className="subtitle">Common Support issues are listed here.  If you have a question or issue that is not answered here, then please <a href="/contactus">Contact Us!</a></h3>

            <div className="card">
                <header className="card-header">
                    <p className="card-header-title">
                        I would like to delete all my data.  How can I do that?
                    </p>
                </header>
                <div className="card-content">
                    <div className="content">
                        <p>You can delete all your data by visiting <a href="/deletealldata">Delete All Data</a>.</p>
                        <p>NOTE: once the procedure has started, there is no way to undo!  All data will be deleted and there is no way to recover.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Support