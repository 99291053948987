import React from 'react'
import '../styles/pagenotfound.css'
import NotFoundImage from '../assets/404-image.png'

function PageNotFound() {
    return (
        <div className="pagenotfound">
            <img src={NotFoundImage} alt="404"></img>
            <h1 className="title">Page Not Found</h1>
            <p>The page you are requesting cannot be located on this server.</p>
        </div>
    )
}

export default PageNotFound