import React, { useRef } from 'react'
import { useState } from 'react';
import { Spinner } from "react-activity";
import ReCAPTCHA from "react-google-recaptcha";
import env from "react-dotenv";
import '../styles/contactus.css'
import 'react-activity/dist/Spinner.css'



const Modal = ({ children, closeModal, modalState, title }) => {
    if (!modalState) {
        return null;
    }

    return (
        <div className="modal is-active">
            <div className="modal-background" onClick={closeModal} />
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{title}</p>
                    <button className="delete" onClick={closeModal} />
                </header>
                <section className="modal-card-body">
                    <div className="content">
                        {children}
                    </div>
                </section>
                <footer className="modal-card-foot">
                    <button className="button" onClick={closeModal}>Close</button>

                </footer>
            </div>
        </div>
    );
}



function ContactUs() {

    const captchaRef = useRef(null)

    const [formData, setFormData] = useState({ name: "", email: "", description: "" });
    const [formValid, setFormValid] = useState(true)
    const [fetching, setFetching] = useState(false)
    const [msgSent, setMsgSent] = useState(false)
    const [msgFailed, setMsgFailed] = useState(false)
    const [modalState, setModalState] = useState(false)

    const toggleModal = () => {
        setModalState((prev) => !prev)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const recaptchaValue = captchaRef.current.getValue();
        captchaRef.current.reset();

        if ((recaptchaValue === null) || (recaptchaValue === '')) {
            return;
        }

        setFetching((prev) => !prev);

        // first we need to verify the recaptcha with google, we will call our own server side function
        // for this.
        let url = env.VERIFY_RECAPTCHA
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'text/plain' },
            body: recaptchaValue
        };
        const respVerify = await fetch(url, requestOptions);
        if (respVerify.status !== 200) {
            setFetching((prev) => !prev);
            // failed the recaptcha check
            setMsgSent(false);
            setMsgFailed(true);
            //setFormValid(false);
            //setFormData({ name: '', email: '', description: '' })
            toggleModal();
            return;
        }

        // all fields were validated at this point, so proceed with sending an email
        url = env.SEND_MAIL_OVER_HTTP
        let formBody = [];
        formBody.push("name=" + formData.name);
        formBody.push("email=" + formData.email);
        formBody.push("message=" + formData.description);
        formBody = formBody.join("&");
        const requestOptions2 = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: formBody
        };

        const respSend = await fetch(url, requestOptions2);
        setFetching((prev) => !prev);
        if (respSend.status !== 200) {
            setMsgSent(false);
            setMsgFailed(true);
            setFormValid(false);
            setFormData({ name: '', email: '', description: '' })
            toggleModal();
            return;
        }

        setMsgSent(true);
        setMsgFailed(false);
        setFormValid(false);
        setFormData({ name: '', email: '', description: '' })
        toggleModal();

    }

    const handleCancel = (event) => {
        event.preventDefault();
        captchaRef.current.reset();
        setFormData({ name: '', email: '', description: '' })
        setFormValid(true);
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setFormData(values => ({ ...values, [name]: value }))
    }


    return (
        <div className='contactus'>
            <h1 className='is-size-1'>Contact Us</h1>
            <p>Have a question, problem, or other issue?  Please fill out the following form and we will get back to you as soon as possible.</p>
            <br />
            {msgSent === true || msgFailed === true ?
                <div className="container is-vcentered">
                    <h3>Please refresh this page to send another message.</h3>
                </div>
                :

                fetching ?
                    <div className="container is-vcentered">
                        <Spinner color="#727981" size={32} speed={1} animating={fetching} />
                    </div>
                    :
                    <div className='control'>
                        <form onSubmit={handleSubmit}>

                            <div className="field">
                                <label className="label">Your Name</label>
                                <div className="control">
                                    <input name='name' className="input" type="text" required value={formData.name} onChange={handleChange} />
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Your Email Address</label>
                                <div className="control">
                                    <input name="email" className="input" type="email" required value={formData.email} onChange={handleChange} />
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Message</label>
                                <div className="control">
                                    <textarea name="description" className="textarea" required onChange={handleChange} value={formData.description}></textarea>
                                </div>
                            </div>

                            <hr />
                            <ReCAPTCHA ref={captchaRef} sitekey={env.REACT_APP_SITE_KEY}  />
                            <br />
                            <div className="field is-grouped">
                                <div className="control">
                                    <button className="button is-link" disabled={!formValid}>Submit</button>
                                </div>
                                <div className="control">
                                    <button type='button' className="button is-link is-light" onClick={handleCancel}>Cancel</button>
                                </div>
                            </div>

                        </form>

                    </div>

            }
            <Modal
                closeModal={toggleModal}
                modalState={modalState}
                title="Contact Form"
            >
                {msgSent && <p>The message was succesfully sent.</p>}
                {msgFailed && <p className="has-text-danger">The message was not sent.  Please try again later.</p>}
            </Modal>

        </div >

    )
}

export default ContactUs