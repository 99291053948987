import React from 'react'
import '../styles/about.css'

function About() {
    return (
        <div className="about">
            <section className="section">
                <h1 className="title">About</h1>
                <p><span className="subtitle">Your Shopping List</span> - Copyright 2023 by <a href="https://salterdiazsolutions.com" alt="Salter Diaz Solutions LLC web site">Salter Diaz Solutions LLC</a>.  All Rights Reserved.</p>
            </section>
            <section className="section">
                <h2 className="title">Credits</h2>
                <div className='content'>
                    <p>Your Shopping List icon:</p>
                    <a href="https://www.flaticon.com/free-icons/order" title="order icons">Order icons created by surang - Flaticon</a>
                    <br />
                    <br />
                    <p>Grocery Shopping video:</p>
                    <a href="https://www.pexels.com/video/woman-crosschecking-items-in-her-grocery-cart-with-the-shopping-list-5100070/">by Gustavo Fring</a>
                </div>
            </section>
        </div>
    );
}

export default About