import React from 'react';
import T from 'prop-types';
import { createSvgIcon, createButton } from "react-social-login-buttons";

const config = {
  activeStyle: { background: "gray" },
  icon: createSvgIcon(Icon),
  style: { background: 'darkgray' },
  text: 'Log in with Email',
};

const EmailLoginButton = createButton(config);

export default EmailLoginButton;

function Icon({ width, height, color }) {
  return (<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
    <g>
      <path d="M29,4H3A3,3,0,0,0,0,7V25a3,3,0,0,0,3,3H29a3,3,0,0,0,3-3V7A3,3,0,0,0,29,4Zm-.72,2L16,14.77,3.72,6ZM30,25a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V7.23l13.42,9.58a1,1,0,0,0,1.16,0L30,7.23Z" fill={color} />
    </g>
  </svg>);
}

Icon.propTypes = {
  width: T.oneOfType([T.number, T.string]),
  height: T.oneOfType([T.number, T.string]),
};
